/* eslint-disable max-lines, react/jsx-max-depth */
import React, {
  ChangeEvent,
  useEffect,
  useState,
  forwardRef,
  ForwardedRef,
} from "react";
import {
  range,
  capitalize,
  some,
  isEmpty,
  get,
  orderBy,
  partition,
} from "lodash";
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps,
  IntlShape,
} from "react-intl";
import { Source as SourceType } from "common/models/symptoms";
import { setTooltip } from "common/actions/tooltip";
import {
  PeriodTitleStyled,
  SymptomTableStyled,
  IntervalSelect,
  SymptomName,
  StepsRow,
  SymptomNameLabel,
  ToggleShowMore,
  VerticalLine,
  HeaderDateStyled,
  HeaderDateWrapper,
  GraphRow,
  DisclaimerButton,
} from "./SymptomTable.styles";
import { averageTime } from "common/utils/general";
import LocalizedMarkdown from "common/components/Markdown/LocalizedMarkdown";
import { Icon } from "@netmedi/frontend-design-system";
import useGrid from "shared/hooks/useGrid";
import { StepsProps } from "../SymptomTableCompact/types";
import { SymptomTableSteps } from "./SymptomTableSteps";
import { SiteSettings } from "common/utils/holvikaari";
import { SymptomTableStepsGraph } from "./SymptomTableStepsGraph";
import SymptomTableRow from "./SymptomTableRow";
import { bundleParentRows } from "./helpers";
import dayjs, { Dayjs } from "dayjs";
import { BodymapTemplate } from "../InputForm/BodyMap/BodymapQuestion.types";

export const VALID_INTERVALS = ["year", "month", "week", "day"] as const;

export type ValidInterval = (typeof VALID_INTERVALS)[number];

export type IntervalOption = {
  value: ValidInterval;
  label: string;
};

export const timeSlots = (
  n = 10,
  end = dayjs(),
  interval: ValidInterval = "week",
) => {
  const endOfLastInterval = dayjs(end.clone()).endOf(interval);
  return range(n)
    .map(slot => [
      dayjs(endOfLastInterval).subtract(slot, interval).startOf(interval),
      dayjs(endOfLastInterval).subtract(slot, interval).endOf(interval),
    ])
    .reverse();
};
export interface ParentSources {
  [name: string]: DataType[];
}

export type DataType = {
  description?: string;
  grade: number;
  grade_min?: number;
  relative_positivity?: number;
  urgency?: number;
  date: string;
  start?: Dayjs;
  end?: Dayjs;
  inverse_positivity?: boolean;
  sources: SourceType[];
  parent_sources?: ParentSources;
  bodymap_template?: BodymapTemplate;
  area_id?: string;
};

type ItemType =
  | "Grade"
  | "Score"
  | "CalendarEntry"
  | "Collection"
  | "SymptomUrgency";

export type RowType = {
  id: string;
  name: string;
  parent_id?: string;
  parent_name?: string;
  description?: string;
  item_type: ItemType;
  data?: (DataType | null | undefined)[];
  hide?: boolean;
  sub_rows?: RowType[];
};

export type SymptomTableProps = {
  rows: RowType[];
  ending: any;
  interval: ValidInterval;
  onZoom?: (centerTime: Dayjs, direction: 1 | -1) => void;
  onIntervalChange: (interval: ValidInterval) => void;
  intl: IntlShape;
  viewedAsStaff: boolean;
  setTooltip: typeof setTooltip;
};

export const getFocusable = (row: RowType): boolean[] => {
  const data = row.data as DataType[];
  return row.item_type === "Grade" ? data.map(y => !!y) : data.map(() => false);
};

export const headerText = (
  start: Dayjs,
  end: Dayjs,
  interval: ValidInterval,
  intl: IntlShape,
  mayOmitEndDate = false,
) => {
  const formatString = intl.formatMessage({
    id: `symptom_table.header_format.${interval}`,
  });

  if (interval === "week") {
    const isSameMonth = start.isSame(end, "month");
    const [startFormat, endFormat] = formatString.split("–").map(format =>
      format.replace(
        /\[(.*)\]/g,
        isSameMonth
          ? "" // remove the "optional" part of the format. C.f. comment above the translation string in the translation file
          : "$1", // otherwise just remove the `[]` as they are used to escape strings in dayjs (and we don't want to escape here)
      ),
    );

    return !mayOmitEndDate || !start.isSame(end, "day")
      ? `${start.format(startFormat)}–${end.format(endFormat)}`
      : start.format(startFormat);
  } else {
    const middle = dayjs(
      500 * (start.clone().unix() + end.clone().unix()),
      undefined,
      true,
    );
    return capitalize(middle.format(formatString));
  }
};

const parentInterval = (interval: ValidInterval) => {
  return VALID_INTERVALS[Math.max(0, VALID_INTERVALS.indexOf(interval) - 1)];
};

export const isPeriodChange = (
  start: Dayjs,
  end: Dayjs,
  interval: ValidInterval,
) => {
  const periodStart = dayjs(end.clone()).startOf(parentInterval(interval));
  const timeToPeriodEnd = dayjs(periodStart.clone()).diff(start);
  const timeBetweenStartAndEnd = dayjs(end.clone()).diff(start);
  return (
    (timeToPeriodEnd < timeBetweenStartAndEnd && timeToPeriodEnd >= 0) ||
    dayjs(start.clone()).isSame(periodStart) ||
    dayjs(end.clone()).isSame(periodStart)
  );
};

export const hasTextFieldAnswer = (grading: DataType | null | undefined) =>
  some(get(grading, "sources"), source =>
    some(get(source, "data_points"), dp => !isEmpty(dp && dp.text)),
  );

export const hasAttachments = (grading: DataType | null | undefined) =>
  some(get(grading, "sources"), source =>
    some(get(source, "data_points"), dp => !isEmpty(dp && dp.attachments)),
  );

const isEvenInterval = (start: Dayjs, interval: ValidInterval) => {
  return dayjs(start.clone()).diff(dayjs(0), interval) % 2 === 0;
};

const timesBetween = (start: Dayjs, end: Dayjs, interval: ValidInterval) => {
  return end.diff(start, interval);
};

export const periodTitle = (intervalStart: Dayjs, interval: ValidInterval) => {
  if (interval === "month") {
    return `${capitalize(dayjs(intervalStart.clone()).format("MMMM"))} ${dayjs(
      intervalStart.clone(),
    ).format("YYYY")}`;
  }
  if (interval === "year") {
    return `${dayjs(intervalStart.clone()).format("YYYY")}`;
  }
  return "";
};

export const isCalendarEntry = (item_type: ItemType) =>
  item_type === "CalendarEntry";

export const stopsVerticalLine = (rows: RowType[], index: number) =>
  isCalendarEntry(get(rows, `[${index + 1}].item_type`)) ||
  index + 1 === rows.length;

export function PeriodTitle(props: PeriodTitleProps) {
  const { periodEnd, childInterval, index, maxIndex } = props;

  const columnWidth = 64;
  const leftButtonOffset = 28;
  const rightButtonOffset = 44;

  const interval = parentInterval(childInterval);

  const intervalStart = dayjs(periodEnd.clone()).startOf(interval);

  const nextIntervalStart = dayjs(periodEnd.clone())
    .add(1, interval)
    .startOf(interval);

  const periodTitleWidth =
    columnWidth * timesBetween(intervalStart, nextIntervalStart, childInterval);

  const columnsAfter = maxIndex - index;

  const wrapperWidth = Math.min(
    periodTitleWidth,
    columnsAfter * columnWidth + rightButtonOffset,
  );

  const prevIntervalStart = dayjs(intervalStart.clone())
    .subtract(1, interval)
    .startOf(interval);

  const prevTitleColumns = timesBetween(
    prevIntervalStart,
    intervalStart,
    childInterval,
  );

  const prevTitleWidth = columnWidth * prevTitleColumns;

  const prevWrapperWidth = Math.min(
    prevTitleWidth,
    index * columnWidth + leftButtonOffset,
  );

  return (
    <>
      {index - prevTitleColumns < 0 && (
        <PeriodTitleStyled
          style={{
            width: prevWrapperWidth,
            left: leftButtonOffset - prevWrapperWidth,
          }}
          key="prev"
        >
          <div
            style={{
              width: prevTitleWidth,
              left: -prevTitleWidth + prevWrapperWidth,
              position: "relative",
            }}
          >
            <h3>{periodTitle(prevIntervalStart, interval)}</h3>
          </div>
        </PeriodTitleStyled>
      )}
      <PeriodTitleStyled style={{ width: wrapperWidth }} key="next">
        <div style={{ width: periodTitleWidth }}>
          <h3>{periodTitle(intervalStart, interval)}</h3>
        </div>
      </PeriodTitleStyled>
    </>
  );
}

type PeriodTitleProps = {
  periodEnd: Dayjs;
  childInterval: (typeof VALID_INTERVALS)[number];
  index: number;
  maxIndex: number;
};

export const HeaderDate = injectIntl((props: HeaderDateProps) => {
  const {
    start,
    end,
    interval,
    index,
    maxIndex,
    onClick,
    intl,
    isOverlapping = true,
  } = props;

  const clickable = VALID_INTERVALS.indexOf(interval) > 0;
  const isEven = isEvenInterval(start, interval);
  const isOdd = !isEven && isOverlapping;
  const periodChange = isPeriodChange(start, end, interval);
  const displayTitle = interval !== "year" && periodChange;

  return (
    <HeaderDateWrapper $relative={isOverlapping}>
      {displayTitle && isOverlapping && (
        <PeriodTitle
          periodEnd={end}
          childInterval={interval}
          index={index}
          maxIndex={maxIndex}
        />
      )}
      <HeaderDateStyled
        $overlapping={isOverlapping}
        $compact={!isOverlapping}
        $top={isEven}
        $bottom={isOdd}
        onClick={() => clickable && onClick && onClick(averageTime(start, end))}
      >
        {headerText(start, end, interval, intl)}
      </HeaderDateStyled>
      {isOverlapping && (
        <VerticalLine
          $periodStart={displayTitle}
          $top={isEven}
          $bottom={isOdd}
          $header
        />
      )}
    </HeaderDateWrapper>
  );
});

export type HeaderDateProps = WrappedComponentProps & {
  start: Dayjs;
  end: Dayjs;
  interval: (typeof VALID_INTERVALS)[number];
  index: number;
  maxIndex: number;
  onClick?: (time: Dayjs) => void;
  isOverlapping?: boolean;
};

// Hiding symptom row if it only has 0 grades and there are at least 4 rows visible
export function shouldHideRow(row: RowType) {
  const isGrade = row?.item_type === "Grade";
  return isGrade && !row?.data?.some(symptom => symptom && symptom.grade > 0);
}

interface HiddenSymptomMessageIdParams {
  viewedAsStaff: boolean;
  numOfRows: number;
}

function getHiddenSymptomMessageId(params: HiddenSymptomMessageIdParams) {
  const { viewedAsStaff, numOfRows } = params;

  if (viewedAsStaff) {
    return numOfRows === 1
      ? "symptom_table.show_zeros_one_staff"
      : "symptom_table.show_zeros_many_staff";
  } else {
    return numOfRows === 1
      ? "symptom_table.show_zeros_one_patient"
      : "symptom_table.show_zeros_many_patient";
  }
}

interface AlertParams {
  hideRows: boolean;
  numOfRows: number;
  viewedAsStaff: boolean;
}

export function alertText(params: AlertParams) {
  const { hideRows, numOfRows, viewedAsStaff } = params;

  if (hideRows) {
    return (
      <FormattedMessage
        id={getHiddenSymptomMessageId({
          viewedAsStaff,
          numOfRows,
        })}
        values={{
          link: (
            <FormattedMessage
              tagName="a"
              id="symptom_table.show_all_symptoms"
            />
          ),
          rows: numOfRows,
        }}
      />
    );
  }

  return <FormattedMessage id="symptom_table.hide_zeros" tagName="a" />;
}

type Props = SymptomTableProps & StepsProps;
// eslint-disable-next-line max-lines-per-function
const SymptomTable = forwardRef(function SymptomTable(
  props: Props,
  ref: ForwardedRef<HTMLTableElement>,
) {
  const [hideZeroRows, setHideZeroRows] = useState(true);
  const {
    rows,
    ending,
    interval,
    onZoom,
    intl,
    steps,
    getSteps,
    startDate,
    endDate,
    client_id,
    setTooltip,
  } = props;

  const columns = rows[0]?.data?.length;

  const columnTimes = timeSlots(columns, dayjs(ending), interval);

  const shouldRenderSteps =
    SiteSettings.enable_device_generated_data &&
    steps !== undefined &&
    interval === steps.interval &&
    steps.data.observations.length > 0;

  const parentRows = bundleParentRows(rows);

  // Adding a boolean to each symptom indicating whether the row has only 0 grades = should be hidden
  const rowsWithHideBool = parentRows.map(row => ({
    ...row,
    hide: shouldHideRow(row),
  }));

  let numOfHiddenRows = rowsWithHideBool.filter(row => row.hide).length;

  let [hiddenRows, visibleRows] = partition(
    rowsWithHideBool,
    row => hideZeroRows && row.hide,
  );

  hiddenRows = orderBy(
    hiddenRows,
    row => row?.data?.filter(data => data),
    "desc",
  );

  const numOfVisibleRows = visibleRows.length;
  // We want to show always at least 4 rows, even if they have only zero grades
  if (numOfVisibleRows < 4) {
    const numOfAddedZeroRows = 4 - numOfVisibleRows;
    visibleRows = visibleRows.concat(hiddenRows.slice(0, numOfAddedZeroRows));
    numOfHiddenRows =
      numOfHiddenRows > numOfAddedZeroRows
        ? numOfHiddenRows - numOfAddedZeroRows
        : 0;
  }

  const focusable = visibleRows.map(getFocusable);
  const { focused, handleKeyDown } = useGrid(focusable, "symptomtable-main");

  const optionIntervals: IntervalOption[] = VALID_INTERVALS.map(x => ({
    value: x,
    label: intl.formatMessage({
      id: `symptom_table.date_intervals.${x}`,
    }),
  }));

  useEffect(() => {
    if (SiteSettings.enable_device_generated_data) {
      getSteps(client_id, {
        interval,
        start_date: startDate,
        end_date: endDate,
      });
    }
  }, [interval]);

  const hideZeroRowsAttrs = {
    onClick: (e: React.MouseEvent) => {
      e.preventDefault();
      setHideZeroRows(!hideZeroRows);
    },
    onKeyDown: (e: React.KeyboardEvent) => {
      e.key === "Enter" && setHideZeroRows(!hideZeroRows);
    },
  };

  return (
    <>
      <SymptomTableStyled
        ref={ref}
        aria-labelledby="dashboard_symptom_table_heading"
      >
        <thead>
          <tr>
            <th>
              <label htmlFor="symptomIntervals">
                <FormattedMessage id="symptom_table.symptom_intervals" />
                <IntervalSelect
                  id="symptomIntervals"
                  value={optionIntervals.find(o => o.value === interval)?.value}
                  options={optionIntervals}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    const value = e.target.value as IntervalOption["value"];
                    props.onIntervalChange(value);
                    if (SiteSettings.enable_device_generated_data) {
                      getSteps(client_id, {
                        interval: value,
                        start_date: startDate,
                        end_date: endDate,
                      });
                    }
                  }}
                />
              </label>
            </th>
            {columnTimes.map(([start, end], idx) => (
              <th key={idx}>
                <HeaderDate
                  start={start}
                  end={end}
                  interval={interval}
                  index={idx}
                  maxIndex={columnTimes.length - 1}
                  onClick={time => onZoom && onZoom(time, -1)}
                />
              </th>
            ))}
          </tr>
        </thead>

        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <tbody onKeyDown={handleKeyDown} data-gridname="symptomtable-main">
          {visibleRows.map((row, rowIdx) => (
            <SymptomTableRow
              key={rowIdx}
              row={row}
              rowIdx={rowIdx}
              visibleRows={visibleRows}
              focused={focused}
              viewedAsStaff={props.viewedAsStaff}
            />
          ))}

          {shouldRenderSteps && (
            <>
              <StepsRow>
                <th>
                  <SymptomName>
                    <FormattedMessage id="symptom_table.steps.footsteps" />
                    <SymptomNameLabel>
                      {interval === "day" ? (
                        <FormattedMessage id="symptom_table.steps.total" />
                      ) : (
                        <FormattedMessage id="symptom_table.steps.daily_average" />
                      )}
                    </SymptomNameLabel>

                    <DisclaimerButton
                      size="small"
                      type="ghost"
                      onClick={event => {
                        setTooltip({
                          content: (
                            <LocalizedMarkdown
                              id={
                                steps.data.device?.platform === "android"
                                  ? "symptom_table.steps.disclaimer_google"
                                  : "symptom_table.steps.disclaimer_apple"
                              }
                            />
                          ),
                          type: "large",
                          position: "right",
                          target: event.target,
                        });
                      }}
                    >
                      <Icon
                        align="left"
                        size="small"
                        name="exclamation_mark_circle_16px"
                      />
                      <FormattedMessage
                        tagName="strong"
                        id="symptom_table.steps.disclaimer"
                      />
                    </DisclaimerButton>
                  </SymptomName>
                </th>
                <SymptomTableSteps
                  columnTimes={columnTimes}
                  interval={interval}
                  steps={steps}
                />
              </StepsRow>
              <GraphRow>
                <SymptomTableStepsGraph
                  columnTimes={columnTimes}
                  interval={interval}
                  steps={steps}
                  isCompact={false}
                />
              </GraphRow>
            </>
          )}
        </tbody>
      </SymptomTableStyled>

      {numOfHiddenRows > 0 && (
        <ToggleShowMore {...hideZeroRowsAttrs}>
          {alertText({
            hideRows: hideZeroRows,
            numOfRows: numOfHiddenRows,
            viewedAsStaff: props.viewedAsStaff,
          })}
        </ToggleShowMore>
      )}
    </>
  );
});

export default injectIntl(SymptomTable, { forwardRef: true });
